<template>
  <div class="">
    <Listbox :model-value="modelValue">
      <div class="relative mt-1">
        <ListboxButton
          class="relative w-full cursor-default rounded-md py-2 pl-3 pr-10 text-sm text-left border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-main sm:leading-6"
        >
          <span class="block truncate">{{ getCurrentTextValue }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            style="z-index: 99"
          >
            <ListboxOption
              v-for="option in options"
              v-slot="{ active, selected }"
              :key="option.value"
              :value="option.value"
              as="template"
            >
              <li
                :class="[
                  active ? 'bg-green-hover' : 'text-gray-900',
                  'relative cursor-default select-none py-2 pl-10 pr-4',
                ]"
                @click="emitChange(option.value)"
              >
                <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">{{
                  option.text
                }}</span>
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-green-main"
                >
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import type { Option } from '~/types/option'

const props = defineProps({
  modelValue: {
    type: [Number, String],
  },
  options: {
    type: Array as PropType<Option[]>,
    default: () => {
      return []
    },
  },
})

const emits = defineEmits<{
  (e: 'update:modelValue', v: any): void
  (e: 'changed'): void
}>()

const getCurrentTextValue = computed((): string => {
  const map: { [key: string]: string } = {}
  props.options?.forEach((val: Option) => {
    map[val.value] = val.text
  })
  // return get(map, props.modelValue)
  return map[props.modelValue]
})

const emitChange = (value: any) => {
  emits('update:modelValue', value)
  emits('changed')
}
</script>
